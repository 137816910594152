import { invokeApi } from 'src/utils';

export const login = async (data) => {
  console.log(data);
  const requestObj = {
    path: `api/app_api/login`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: JSON.stringify(data),
  };

  return invokeApi(requestObj);
};


export const _verify_google_auth = async (data) => {
  console.log(data);
  const requestObj = {
    path: `api/app_api/verify_google_auth`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
      // 'Access-Control-Allow-Origin': "*"
    
    },
    postData: JSON.stringify(data),
  };

  return invokeApi(requestObj);
};


export const _email_verification = async (data) => {
  console.log(data);
  const requestObj = {
    path: `api/app_api/email_verification`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: JSON.stringify(data),
  };

  return invokeApi(requestObj);
};
export const _code_verification = async (data) => {
  console.log(data);
  const requestObj = {
    path: `api/app_api/code_verification`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: JSON.stringify(data),
  };

  return invokeApi(requestObj);
};

export const _logout = async (data) => {
  const requestObj = {
    path: `api/app_api/logout`,
    method: 'POST',
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _change_password = async (data) => {
  const requestObj = {
    path: `api/app_api/change_password`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _reset_password = async (data) => {
  const requestObj = {
    path: `api/app_api/reset_password`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
 
