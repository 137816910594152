// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
    /* "children" key can be added in these objects, example:children:[{...}] */
  },
 
  {
    title: "Clients",
    path: "/clients",
    icon: getIcon("fe:users"),
  },
  {
    title: "Projects",
    path: "/projects",
    icon: getIcon("la:project-diagram"),
  },
  {
    title: "Access",
    path: "/access",
    icon: getIcon("uil:lock-access"),
  },
  {
    title: "Admins",
    path: "/admins",
    icon: getIcon("raphael:user"),
  },
  {
    title: "QR Code",
    path: "/qr-code",
    icon: getIcon("mdi:qrcode-plus"),
  },
  {
    title: "Documentation",
    path: "/documentation",
    icon: getIcon("oui:documentation"),
  },
  // {
  //   title: "users",
  //   path: "/users",
  //   icon: getIcon("raphael:user"),
  // },
  /* example: collapsible sidebar routes
    {
      title: "parent",
      path: "/parent",
      icon: getIcon("fe:users"),
    children: [
        {
            title: "child1",
        path: "/parent/child1",
        icon: getIcon("fe:users"),
      },
      {
        title: "child2",
        path: "/parent/child2",
        icon: getIcon("fe:users"),
      },
    ],
  },
  */
];

export default navConfig;
