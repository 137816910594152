import { invokeApi } from "src/utils";


export const _list_access = async (data) => {

    const requestObj = {
      path: `api/access/list_access?page=0&limit=99999`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_access = async (data) => {

    const requestObj = {
      path: `api/access/add_access`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

 export const _edit_access = async (id, data) => {
  const requestObj = {
    path: `api/access/edit_access/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

  export const _delete_access = async (id) => {
    const requestObj = {
      path: `api/access/delete_access/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _detail_access = async (id) => {
    const requestObj = {
      path: `api/access/detail_access/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  export const _get_file_details = async (id) => {
    const requestObj = {
      path: `api/app_api/get_file_details/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

//   export const _active_projects = async () => {
//     const requestObj = {
//       path: `api/project/active_projects`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
//     return invokeApi(requestObj);
//   };
