import { invokeApi } from "src/utils";


export const _list_project = async (data) => {
 
    const requestObj = {
      path: `api/project/list_project?page=0&limit=99999`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_project = async (data) => {

    const requestObj = {
      path: `api/project/add_project`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

 export const _edit_project = async (id, data) => {
  const requestObj = {
    path: `api/project/edit_project/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

  export const _delete_project = async (id) => {
    const requestObj = {
      path: `api/project/delete_project/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _detail_project = async (id) => {
    const requestObj = {
      path: `api/project/detail_project/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _active_projects = async () => {
    const requestObj = {
      path: `api/project/active_projects`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _client_active_projects = async (id) => {
    const requestObj = {
      path: `api/project/client_active_projects/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
