// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import SummaryCard from "./components/SummaryCard";
import { CircularLoader, Page } from "src/components";
//hooks
import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import { _dashboard_init } from "src/DAL/dashboard";

// ----------------------------------------------------------------------

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([
    {
      color: "success",
      title: "Clients",
      count: 0,
      icon: "fe:users",
    },
    {
      color: "info",
      title: "Projects",
      count: 0,
      icon: "la:project-diagram",
    },
    {
      color: "secondary",
      title: "Access",
      count: 0,
      icon: "uil:lock-access",
    },
  ]);

  const theme = useTheme();
  const { _get_user_profile } = useAppContext();

  const fetchDashboard = async () => {
    setIsLoading(true);
    const response = await _dashboard_init();

    // // list_brand

    console.log("response _dashboard_init", response);
    if (response.code === 200) {
      setDashboardData([
        {
          color: "success",
          title: "Clients",
          count: response.dashboard.clients,
          icon: "fe:users",
        },
        {
          color: "info",
          title: "Projects",
          count: response.dashboard.projects,
          icon: "la:project-diagram",
        },
        {
          color: "secondary",
          title: "Access",
          count: response.dashboard.accesses,
          icon: "uil:lock-access",
        },
      ]);
      setIsLoading(false); // Set isLoading to false after 2 seconds
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {dashboardData.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={3}>
              <SummaryCard
                color={item.color}
                title={item.title}
                count={item.count}
                icon={item.icon}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
