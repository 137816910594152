import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import CustomModal from "./GeneralComponents/CustomModal";
// import { get_root_value } from "src/utils/domUtils";
// import { get_root_value } from "src/utils/domUtils";
import { Typography, Box } from "@mui/material"; // Import required Material-UI components
import Iconify from "./Iconify";
import { useTheme } from "@mui/material/styles";
import CustomModalDel from "./GeneralComponents/CustomModalDel";

const style2 = {
  width: "60%",
  // height: "auto", // Set height to auto
  height: "40vh",
  minWidth: "60%",
  minHeight: "300px", // Set a maximum height for the modal
  // maxHeight: "80vh", // Set a maximum height for the modal

  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 0,
  overflow: "hidden", // Hide vertical scroll
};

function DelPopup({ title, message, onOk, open, setOpen }) {
  // Inside your component
  const theme = useTheme();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomModalDel
        isOpenModal={open}
        handleCloseModal={handleCloseDialog}
        pageTitle={title}
        // customStyle={style2}
        componentToPassDown={
          <div className="mt-5 container pt-4 pb-0">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center", // Center content vertically
                maxWidth: "100%", // Adjust the maximum width as needed
                maxHeight: "100%", // Adjust the maximum width as needed
                margin: "auto", // Center the container horizontally
                padding: "20px",
                paddingTop: "0px",
                // border: "1px solid #e0e0e0", // Add a border for separation
                borderRadius: "8px", // Add some border radius for rounded corners
                backgroundColor: "#fff", // Set a background color
                // height: "100vh", // Make the box cover the entire viewport height
              }}
            >
              <Typography variant="h6" gutterBottom>
                {message}
              </Typography>

              <Box
                sx={{
                  alignSelf: "flex-center",
                  marginTop: `10px`,
                  [theme.breakpoints.up("sm")]: {
                    marginTop: `10px`,
                  },
                }}
              >
                <Button
                  onClick={handleCloseDialog}
                  variant="outlined"
                  color="error"
                  sx={{ marginRight: 1 }}
                  startIcon={<Iconify icon="carbon:close-filled" />}
                >
                  Cancel
                </Button>

                <Button
                  onClick={onOk}
                  variant="contained"
                  color="primary"
                  startIcon={<Iconify icon="healthicons:yes" />}
                >
                  Agree
                </Button>
              </Box>
            </Box>
          </div>
        }
      />
    </>
  );
}

export default DelPopup;
