import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Toolbar,
  InputAdornment,
  Zoom,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import ClientListHead from "./components/ClientListHead";
import ClientListToolbar from "./components/ClientListToolbar";
import ClientMoreMenu from "./components/ClientMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
 
import { useAppContext } from "src/hooks";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { capitalizeFirstLetter, truncateString } from "src/utils/formatText";
import { _list_access } from "src/DAL/access";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "client", label: "Client Name", alignRight: false },
  { id: "project", label: "Project Name", alignRight: false },
  { id: "env", label: "Environment", alignRight: false },
  // { id: "description", label: "Description", alignRight: false },
  // { id: "active_status", label: "Status", alignRight: false },

  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Access() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const { enqueueSnackbar } = useSnackbar();

 
  const myElementRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }
  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/access/add-access");
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _list_access({
      search: "",
    });

    // list_brand

    console.log("response _list_access", response);
    if (response.code === 200) {
      const students = response.access.map((student, index) => ({
        ...student,
        id: student._id,
        env: student.env?student.env:"--",
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setStudents(students);
      // setIsLoading(false);
      // setTimeout(() => {
      //   setIsLoading(false); // Set isLoading to false after 2 seconds
      // }, 1000); // 2000 milliseconds = 2 seconds
      setIsLoading(false); // Set isLoading to false after 2 seconds
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const SearchStudents = async (searchText) => {
    let response = [];
    // setIsLoading(true);
    let search_text = searchText;

    if (!search_text || search_text === "" || search_text === null) {
      search_text = "";
      response = await _list_access();
    } else {
      response = await _list_access({
        search: search_text,
      });
    }

    // const response = await listStudentSearch(searchText);

    console.log("response _addEnquiryStudent", response);

    if (response.code === 200) {
      const students = response.access.map((student, index) => ({
        ...student,
        id: student._id,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      setIsLoading(true);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  useEffect(() => {
    console.log("students", students);
  }, [students]);

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Access">
      <Container maxWidth="xl">
      
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <Typography variant="h4">Access</Typography>
              
                <Button
                  onClick={handleNav}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Access
                </Button>
             
            </Stack>

            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              {/* <ClientListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

              <Scrollbar>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                  />
                  {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                <CustomResetButton handleReset={handleReset}/>

                  {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
                </Stack>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <ClientListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={students.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            id,
                            name,
                            status,
                            client,
                            project,
                            env,
                            number,
                          } = row;
                          // const isItemSelected = selected.indexOf(name) !== -1;
                          // let number = index + 1;
                          // let { course, others } = row;
                          // let titleToShow = others.length > 0 ? others[0].course_title : course[0].title;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              // selected={isItemSelected}
                              // aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, name)
                              }
                            /> */}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {index +1}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                <RouterLink
                                    to={`/access/detail-access/${row.id}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                    // className="on_click_text" // Add the class name you want here
                                  >
                                    <div className="on_click_text">
                                    {capitalizeFirstLetter(client.name)}
                                    </div>
                                  </RouterLink>

                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                <RouterLink
                                    to={`/access/detail-access/${row.id}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                    // className="on_click_text" // Add the class name you want here
                                  >
                                    <div className="on_click_text">
                                    {capitalizeFirstLetter(project.name)}
                                    </div>
                                  </RouterLink>

                              </TableCell>

                               
                              <TableCell align="left" style={myStyle}>
                                {env}
                              </TableCell>
 
                             
 
                                <TableCell align="right">
                                  <ClientMoreMenu
                                    row={row}
                                    students={students}
                                    onStudentsUpdate={handleStudentsUpdate}
                                  />
                                </TableCell>
                             
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  // { value: students.length, label: "All" },
                ]}
                component="div"
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
       
      </Container>

      

<CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Access Detail"
        componentToPassDown={
          <div className="mt-5">
            {/* <BrandDetail selectedRowStudent={selectedRowStudent} /> */}
          </div>
        }
      />
    </Page>
  );
}
