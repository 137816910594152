import * as Yup from "yup";
import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { useSnackbar } from "notistack";
import { _verify_google_auth, login } from "src/DAL/auth";
import { useAppContext } from "src/hooks";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import GoogleAuthenticator from "./GoogleAuthenticator";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import EmailAuthenticator from "./EmailAuthenticator";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    email: "",
    password: "",
    code: "",
    authenticator: "email", // Default to "email" option
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { _set_user_profile } = useAppContext();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const myElementRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      email: formInputs.email.trim(),
      password: formInputs.password.trim(),
      type: 0,
    };

    setIsLoading(true);
    const result = await login(obj);
    console.log("result login ___login __test", result);
    if (result.code === 200) {
      setIsLoading(false);
      // setVerificationStatus(result.user.verification_status);
      handleOpenModal(result.qr_code);

      // localStorage.setItem("token", result.token);

      // // user.role.privilege
      // let user = {
      //   ...result.user,
      //   first_name: result.user.first_name,
      //   last_name: result.user.last_name,
      //   email: result.user?.user_id.email,
      //   image: result.user.profile_image,
      //   // type: result.user.type,

      // };

      // console.log('user login ___login __test',user);

      // console.log('JSON.stringify(user)   __JSON.stringify(user)',JSON.stringify(user));
      // localStorage.setItem("user", JSON.stringify(user));
      // const storedObjectString = localStorage.getItem("user");
      // const storedObject = JSON.parse(storedObjectString);

      // _set_user_profile(storedObject);
      // navigate("/dashboard");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            value={formInputs.email}
            onChange={handleChange}
            name="email"
            required
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
          />

          <TextField
            value={formInputs.password}
            onChange={handleChange}
            fullWidth
            name="password"
            required
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <Link component={RouterLink} variant='subtitle2' to='/forgot-password' underline='hover'>
          Forgot password?
        </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Login
        </LoadingButton>
      </form>

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Authenticator"
        componentToPassDown={
          <div className="mt-5">
            <div className="container mt-5 px-5">
              {verificationStatus === true ? (
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="authenticator"
                    name="authenticator"
                    value={formInputs.authenticator}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="email"
                      control={<Radio />}
                      label="Email Authenticator"
                    />
                    <FormControlLabel
                      value="google"
                      control={<Radio />}
                      label="Google Authenticator"
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}

              {formInputs.authenticator === "google" &&
              verificationStatus === true ? (
                <GoogleAuthenticator
                  formInputs={formInputs}
                  setFormInputs={setFormInputs}
                  selectedRowStudent={selectedRowStudent}
                />
              ) : (
                <EmailAuthenticator
                  formInputs={formInputs}
                  setFormInputs={setFormInputs}
                  selectedRowStudent={selectedRowStudent}
                />
              )}
              {/* verification_status */}
            </div>
          </div>
        }
      />
    </>
  );
}
