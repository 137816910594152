import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh"; // You can replace RefreshIcon with the icon you prefer

// ----------------------------------------------------------------------

export default function CustomResetButton({ handleReset }) {
  return (
    <>
      <IconButton color="primary" onClick={handleReset} sx={{ p: 0.5 }}>
        <RefreshIcon />
      </IconButton>
    </>
  );
}
