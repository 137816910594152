import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Button,
  FormHelperText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { _add_client } from "src/DAL/clients";
import CustomPasswordField from "src/components/GeneralComponents/CustomPasswordField";
import { _signup_admin } from "src/DAL/admins";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import CustomImageUploader1 from "src/components/GeneralComponents/CustomImageUploader1";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  _add_documentation,
  _detail_documentation,
  _edit_documentation,
  _upload_file,
} from "src/DAL/documentation";

const EditDocumentation = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    documentation_title: "",
    file: "",
    description: "",
  });

  const [fileName, setFileName] = useState("");

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const fileChangedHandler = async (e) => {
    const filePath = e.target.files[0];

    setInputs((prev) => {
      return {
        ...prev,
        file: filePath,
      };
    });
    setFileName(filePath);
  };

  const getDocumentData = async () => {
    setIsLoading(true);
    const result = await _detail_documentation(id);
    if (result.code === 200) {
      setInputs({
        ["documentation_title"]: result?.data?.title,
        ["description"]: result?.data?.description,
        ["file"]: result?.data?.file_path,
      });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let filePath = "";
    if (fileName !== "") {
      const formData = new FormData();
      formData.append("file", fileName);

      const newData = await _upload_file(formData);
      if (newData.code === 200) {
        filePath = newData.path;
      }
    }

    const postData = {
      title: inputs?.documentation_title,
      description: inputs.description,
      file_path: filePath !== "" ? filePath : inputs?.file,
    };

    const result = await _edit_documentation(id, postData);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edit Successfully!", { variant: "success" });
      navigate("/documentation");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDocumentData();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add User">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            Edit Documentation
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                <TextField
                  fullWidth
                  label="Title"
                  value={inputs.documentation_title}
                  onChange={handleChange}
                  name="documentation_title"
                  required
                  //   inputProps={{
                  //     pattern: "[A-Za-z]{3,}",
                  //     title:
                  //       "Please enter at least 3 letters with only alphabetical characters.",
                  //   }}
                />

                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <div className="row w-100 div-style ms-0 pt-0">
                    <div className="col-2"></div>
                    <div className="col-5">
                      {fileName ? (
                        fileName?.name
                      ) : (
                        <>
                          <div className="text-end">
                            <Iconify
                              style={{ color: "grey" }}
                              icon="bxs:file-blank"
                              width={24}
                              height={24}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-5 text-end pt-2">
                      <label htmlFor="contained-button-file">
                        <Input
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          name="image"
                          hidden
                          onChange={fileChangedHandler}
                        />

                        <Button
                          // className="small-contained-button"
                          // variant="outlined"
                          startIcon={<FileUploadIcon className="uploadIcon" />}
                          component="span"
                        >
                          Upload File
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
              </Stack>
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Description"
                  multiline
                  rows={7}
                  value={inputs?.description}
                  
                  name="description"
                  onChange={handleChange}
                />
                <FormHelperText>Maximum limit 500 characters</FormHelperText>
              </FormControl>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Edit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default EditDocumentation;
