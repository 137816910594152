import * as Yup from "yup";
import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { useSnackbar } from "notistack";
import { _verify_google_auth, login } from "src/DAL/auth";
import { useAppContext } from "src/hooks";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { _generate_qr_code } from "src/DAL/qr_code";

// ----------------------------------------------------------------------

export default function QRCodeAuthenticator({
  formInputs,
  setFormInputs,
  selectedRowStudent,
  setSelectedRowStudent,
  authStatus,
  setAuthStatus,
}) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { _set_user_profile } = useAppContext();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const generateCode = async () => {
    setIsLoadingButton(true);
    // setShowGetCodeText(false);
    const response = await _generate_qr_code();
    console.log("_generate_qr_code _generate_qr_code", response);

    if (response.code === 200) {
      setSelectedRowStudent(response.qr_code?.qr_code_path);
      setIsLoadingButton(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoadingButton(false);
    }
  };

  const handleSubmitCode = async (e) => {
    e.preventDefault();
    const obj = {
      email: formInputs.email.trim(),
      token: formInputs.code,
    };

    setIsLoadingButton(true);

    const result = await _verify_google_auth(obj);
    console.log(
      "_verify_google_auth _verify_google_auth _verify_google_auth __test",
      result
    );
    if (result.code === 200) {
      // setIsLoading(false);

      navigate("/dashboard");
      setIsLoadingButton(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingButton(false);
    }
  };

  console.log("selectedRowStudent  ___selectedRowStudent", selectedRowStudent);
  return (
    <div className="container px-5">
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        // mb={4.5}
        // mt={2}
        // pt={2}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Google Authenticator
        </Typography>
        <hr />
      </Stack> */}

      {/* <hr /> */}

      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        // mt={5}
        // mb={5}
        // pt={5}
        // pb={5}
      >
        <Typography variant="h5">
          Google Authenticator{" "}
          {authStatus ? (
            <span style={{ color: "green" }}>(Already Verified)</span>
          ) : (
            ""
          )}
        </Typography>

        <Typography variant="body2" align="center">
          If you don't have a Google Authenticator code
        </Typography>

        <Typography
          variant="subtitle2"
          underline="hover"
          component={Link} // Use Link component from MUI for better styling and accessibility
          onClick={generateCode} // Call getEmailCode when clicked
          style={{ cursor: "pointer" }} // Optional: Change cursor to pointer to indicate it's clickable
        >
          Generate QR Code
        </Typography>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        // mb={2}
        // mt={2}
        // pt={2}
      >
        <img src={selectedRowStudent} />
      </Stack>

      <form autoComplete="off" className="mt-2" onSubmit={handleSubmitCode}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              value={formInputs.code}
              onChange={handleChange}
              name="code"
              required
              fullWidth
              // autoComplete='username'
              type="code"
              label="Google Authenticator Code"
            />
            <LoadingButton
              sx={{ height: "50px", marginTop: "0px" }}
              size="small"
              type="submit"
              variant="contained"
              loading={isLoadingButton}
            >
              {/* Validate */}
              <Iconify icon={"zondicons:arrow-right"} width={22} height={22} />
            </LoadingButton>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <Link component={RouterLink} variant='subtitle2' to='/forgot-password' underline='hover'>
          Forgot password?
        </Link> */}
        </Stack>

        {/* <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isLoading}>
        Validate
      </LoadingButton> */}
      </form>
    </div>
  );
}
