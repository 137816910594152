import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
// import { s3baseUrl } from "src/config/config";

import { capitalizeFirstLetter } from "src/utils/formatText";
import { _detail_client, _edit_client } from "src/DAL/clients";
 

const EditClient = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    // last_name: "",
    email: "",
    gender: "male",
    course: [],
    others: [],
    mobile_number: "",
    fee_status: "paid",
    enquiry_status: "active",
    enquiry_status_reason: "",
    education: "",
    image: "",
    address: "",
    reference: "",
    batches: [],
    dob: "",

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    // date_of_birth: null, // Set initial value to null
    // date_of_birth : dayjs("2001-03-03").toDate(),
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);

    let postData = {
      name: formInputs.name,
      status: formInputs.status,
    };
    console.log("postData", postData);


    setIsLoading(true);
    const result = await _edit_client(id,postData);
    console.log("result _edit_client", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/clients");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
 
 
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_client(id);
    console.log("detail_client _detail_client", response);
    // enquiry_form

    if (response.code === 200) {
      setFormInputs({
        name: capitalizeFirstLetter(response.client.name),
         status: response.client.status,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  
 
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Client">
    <div className="container px-4 mb-5 container_in_all_pages">
    <Grid
      item
      xs={12}
      sm={10}
      md={8}
      lg={7}
      xl={7}
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <IconButton onClick={handleNav} sx={{ flex: 0 }}>
        <Iconify icon="ep:back" />
      </IconButton>
    </Grid>
    <Grid
      item
      xs={12}
      sm={10}
      md={8}
      lg={7}
      xl={7}
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <h4 className="title_heading_in_all_pages_top_left"> Edit Client </h4>
    </Grid>


    <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              value={formInputs.name}
              onChange={handleChange}
              name="name"
              required
              // inputProps={{
              //   pattern: "[A-Za-z]{3,}",
              //   title:
              //     "Please enter at least 3 letters with only alphabetical characters.",
              // }}
            />

            <TextField
              fullWidth
              label="Status"
              select
              // required
              value={formInputs.status}
              onChange={handleChange}
              name="status"
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Stack>

          <Stack alignItems="end">
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              loading={isLoading}
              sx={{ width: "120px" }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Grid>
  </div>
</Page>
  );
};

export default EditClient;
