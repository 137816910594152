import PropTypes from "prop-types";
// material
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  TextField,
} from "@mui/material";
import CustomImageUploader1 from "src/components/GeneralComponents/CustomImageUploader1";

// ----------------------------------------------------------------------

// ClientListHead.propTypes = {
//   order: PropTypes.oneOf(["asc", "desc"]),
//   orderBy: PropTypes.string,
//   rowCount: PropTypes.number,
//   headLabel: PropTypes.array,
//   numSelected: PropTypes.number,
//   onRequestSort: PropTypes.func,
//   onSelectAllClick: PropTypes.func,
// };

export default function InputTextOrFile({
  index,
  innerIndex,
  value,
  innerKey,

  handleInnerInputChange,
  setData,
  path,
}) {

  return (
    <>
      {innerKey.value.type === "1" ? (
        <TextField
          fullWidth
          label={`Value ${innerIndex + 1}`}
          value={innerKey.value.value}
          onChange={(e) =>
            handleInnerInputChange(index, innerIndex, "value", e.target.value)
          }
          required
          style={{ marginRight: "10px" }}
        />
      ) : (
        <Box sx={{width:"100%"}}> 
        <CustomImageUploader1
          pathOfUploadedFile={path}
          setPathOfUploadedFile={() => {}}
          // imageFileHeading={"Image File"}
          imageFileHeading={``}
          imageFileSubHeading={""}
          imageFileLimitSizeUpload={5}
          imageFileButtonText={`Upload ${innerIndex + 1}`}
          imageID={innerIndex}
          fileName={`File ${innerIndex + 1}`}
          setData={setData}
          index={index}
          innerIndex={innerIndex}
          category={value}
        />
        </Box>
      )}
    </>
  );
}
