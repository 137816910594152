import { invokeApi } from "src/utils";

export const _add_documentation = async (data) => {

    const requestObj = {
      path: `api/documentation/add_documentation`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _upload_file = async (data) => {

    const requestObj = {
      path: `api/app_api/upload_file`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _detail_documentation = async (id) => {
    const requestObj = {
      path: `api/documentation/detail_documentation/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _list_documentation = async (data) => {
    const requestObj = {
      path: `api/documentation/list_documentation?page&limit`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData:data
    };
    return invokeApi(requestObj);
  };

  export const _delete_documentation = async (id) => {
    const requestObj = {
      path: `api/documentation/delete_documentation/${id}`,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _edit_documentation = async (id, data) => {
    const requestObj = {
      path: `api/documentation/edit_documentation/${id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };