import { invokeApi } from "src/utils";


export const _list_client = async (data) => {

    const requestObj = {
      path: `api/client/list_client?page=0&limit=99999`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_client = async (data) => {

    const requestObj = {
      path: `api/client/add_client`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

 export const _edit_client = async (id, data) => {
  const requestObj = {
    path: `api/client/edit_client/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

  export const _delete_client = async (id) => {
    const requestObj = {
      path: `api/client/delete_client/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _detail_client = async (id) => {
    const requestObj = {
      path: `api/client/detail_client/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _active_clients = async () => {
    const requestObj = {
      path: `api/client/active_clients`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
