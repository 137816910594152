import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  Dashboard,
  ForgotPassword,
  Login,
  Page404,
  Profile,
  Register,
  Settings,
} from "./pages";
import Clients from "./pages/Clients/List/Clients";
import AddClient from "./pages/Clients/Add/AddClient";
import EditClient from "./pages/Clients/Edit/EditClient";
import Projects from "./pages/Projects/List/Projects";
import AddProject from "./pages/Projects/Add/AddProject";
import EditProject from "./pages/Projects/Edit/EditProject";
import Access from "./pages/Access/List/Access";
import AddAccess from "./pages/Access/Add/AddAccess";
import EditAccess from "./pages/Access/Edit/EditAccess";
import DetailAccess from "./pages/Access/List/DetailAccess";
import EditUser from "./pages/Users/Edit/EditUser";
import Users from "./pages/Users/List/Users";
import AddUser from "./pages/Users/Add/AddUser";
import ListAdmin from "./pages/Admins/List/ListAdmin";
import AddAdmin from "./pages/Admins/Add/AddUser";
import EditAdmin from "./pages/Admins/Edit/EditAdmin";
import QRCode from "./pages/QRCode/QRCode";
import ChangePassword from "./pages/profile/ChangePassword/ChangePassword";
import AddDocumentation from "./pages/Documentation/Add/AddDocumentation";
import ListDocumentation from "./pages/Documentation/List/ListDocumentation";
import EditDocumentation from "./pages/Documentation/Edit/EditDocumentation";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },

          ///////////////// Clients
          { path: "clients", element: <Clients /> },
          {
            path: "clients/add-client",
            element: <AddClient />,
          },
          {
            path: "clients/edit-client/:id",
            element: <EditClient />,
          },

          ///////////////// Projects
          { path: "projects", element: <Projects /> },
          {
            path: "projects/add-project",
            element: <AddProject />,
          },
          {
            path: "projects/edit-project/:id",
            element: <EditProject />,
          },
          /////////////////  Access
          { path: "access", element: <Access /> },
          {
            path: "access/add-access",
            element: <AddAccess />,
          },
          {
            path: "access/edit-access/:id",
            element: <EditAccess />,
          },
          {
            path: "access/detail-access/:id",
            element: <DetailAccess />,
          },

          //  ///////////////// users
          //  { path: "users", element: <Users /> },
          //  {
          //    path: "users/add-user",
          //    element: <AddUser />,
          //  },
          //  {
          //    path: "users/edit-user/:id",
          //    element: <EditUser />,
          //  },

           ///////////////// Admins
           { path: "admins", element: <ListAdmin /> },
           {
             path: "admins/add-admin",
             element: <AddAdmin />,
           },
           {
             path: "admins/edit-admin/:id",
             element: <EditAdmin />,
           },
           ///////////////// QR Code
           { path: "qr-code", element: <QRCode /> },
           
            
 
        { path: "profile", element: <Profile /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "settings", element: <Settings /> },
        { path: "documentation", element: <ListDocumentation /> },
        { path: "add-documentation", element: <AddDocumentation /> },
        { path: "edit-documentation/:id", element: <EditDocumentation /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
