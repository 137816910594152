import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Box,
  MenuItem,
  Stack,
  Grid,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { capitalizeAllFirstLetters } from "src/utils/formatText";
import { CircularLoader, Iconify, Page } from "src/components";
import {
  _active_projects,
  _add_project,
  _client_active_projects,
} from "src/DAL/projects";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { _add_access } from "src/DAL/access";
import { useSnackbar } from "notistack";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputTextOrFile from "../List/components/InputTextOrFile";
import { _active_clients } from "src/DAL/clients";
import { encryptValue } from "src/utils/crypto";

const AddAccess = () => {
  const [data, setData] = useState([
    {
      outerKey: "Backend",
      innerKeys: [
        {
          innerKey: "Domain Name",
          value: {
            type: "1",
            value: "",
          },
        },

        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File",
          value: {
            type: "2",
            value: "",
          },
        },
        {
          innerKey: "GitHub Repo",
          value: {
            type: "1",
            value: "",
          },
        },

        {
          innerKey: "GitHub Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Developer Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Platform",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },

    // {
    //   outerKey: "Enviroment",
    //   innerKeys: [
    //     {
    //       innerKey: "Env",
    //       value: {
    //         type: "1",
    //         value: "",
    //       },
    //     },
    //   ],
    // },

    {
      outerKey: "S3 Bucket",
      innerKeys: [
        {
          innerKey: "S3 Access Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "S3 Secret Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "S3 Bucket Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "S3 Region",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "SES",
      innerKeys: [
        {
          innerKey: "SES Access Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SES Secret Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SES Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SES Region",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "DataBase",
      innerKeys: [
        {
          innerKey: "Database Url",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },

    {
      outerKey: "Client",
      innerKeys: [
        {
          innerKey: "Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "User Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "IP",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Password",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File",
          value: {
            type: "2",
            value: "",
          },
        },

        {
          innerKey: "Repository",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Repository Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        // {
        //   innerKey: "Env",
        //   value: {
        //     type: "1",
        //     value: "",
        //   },
        // },
        {
          innerKey: "Developer",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Platform",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "Admin",
      innerKeys: [
        {
          innerKey: "Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "User Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "IP",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Password",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File",
          value: {
            type: "2",
            value: "",
          },
        },

        {
          innerKey: "Repository",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Repository Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        // {
        //   innerKey: "Env",
        //   value: {
        //     type: "1",
        //     value: "",
        //   },
        // },
        {
          innerKey: "Developer",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Platform",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "Next Website",
      innerKeys: [
        {
          innerKey: "Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "User Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "IP",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Password",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File",
          value: {
            type: "2",
            value: "",
          },
        },

        {
          innerKey: "Repository",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Repository Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        // {
        //   innerKey: "Env",
        //   value: {
        //     type: "1",
        //     value: "",
        //   },
        // },
        {
          innerKey: "Developer",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Platform",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
  ]);
  const [project, setProject] = useState([]);
  const [clients, setClients] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",
    client: "",
    project: "",
    env: "",
    status: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const handleAddField = () => {
    setData([
      ...data,
      {
        outerKey: "",
        innerKeys: [
          {
            innerKey: "",
            value: {
              type: "1",
              value: "",
            },
          },
        ],
      },
    ]);
  };

  const handleDeleteField = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const handleInputChange = (index, category, newValue) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index][category] = newValue;
      return newData;
    });
  };

  const handleInnerAddField = (index) => {
    const newData = [...data];
    newData[index].innerKeys.push({
      innerKey: "",
      value: {
        type: "1",
        value: "",
      },
    });
    setData(newData);
  };

  const handleInnerDeleteField = (index, innerIndex) => {
    const newData = [...data];
    newData[index].innerKeys.splice(innerIndex, 1);
    setData(newData);
  };

  const handleInnerInputChangeName = (
    index,
    innerIndex,
    category,
    newValue
  ) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].innerKeys[innerIndex][category] = newValue;
      return newData;
    });
  };

  const handleInnerInputChange = (index, innerIndex, category, newValue) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].innerKeys[innerIndex][category] = {
        // type: "1",
        type: newData[index].innerKeys[innerIndex][category].type
          ? newData[index].innerKeys[innerIndex][category].type
          : "1",
        value: newValue,
      };
      return newData;
    });
  };
  const handleInnerInputChangeFile = (
    index,
    innerIndex,
    category,
    newValue
  ) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index].innerKeys[innerIndex][category] = {
        type: newValue, // Set the type based on the newValue
        value: newData[index].innerKeys[innerIndex][category].value
          ? newData[index].innerKeys[innerIndex][category].value
          : "",
      };
      return newData;
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  const handleChangeClient = (e) => {
    const { name, value } = e.target;
    setProject([]);
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      project: "",
    }));

    fetchProject(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("formInputs", formInputs);

    // Transform the data to the desired format
    let transformedData = data.map(({ outerKey, innerKeys }) => ({
      [outerKey]: innerKeys.reduce((acc, { innerKey, value }) => {
        acc[innerKey] = {
          ...value,
          value: value.type === "1" ? encryptValue(value.value) : value.value,
        };
        return acc;
      }, {}),
    }));

    let postData = {
      client: formInputs.client,
      project: formInputs.project,
      env: formInputs.env,
      access: transformedData,
    };
    console.log("postData", postData);

    setIsLoading(true);
    const result = await _add_access(postData);
    console.log("result _add_access", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      navigate("/access");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchClients = async () => {
    setIsLoading(true);
    const response = await _active_clients();
    console.log("response _active_clients _active_clients", response);
    if (response.code === 200) {
      setClients(response.clients);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  const fetchProject = async (_ID) => {
    // setIsLoading(true);
    const response = await _client_active_projects(_ID);
    console.log(
      "response _client_active_projects _client_active_projects",
      response
    );
    if (response.code === 200) {
      setProject(response.projects);
      // setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchClients();
    // fetchProject();
  }, []);

  useEffect(() => {
    console.log("data __additionalFields", data);

    // // Transform the data to the desired format
    // let transformedData = data.map(({ outerKey, innerKeys }) => ({
    //   [outerKey]: innerKeys.reduce((acc, { innerKey, value }) => {
    //     acc[innerKey] = value;
    //     return acc;
    //   }, {}),
    // }));

    // console.log("transformedData __additionalFields", transformedData);
  }, [data]);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Access">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Access </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Client"
                  select
                  value={formInputs.client}
                  onChange={handleChangeClient}
                  name="client"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {clients.map((client) => (
                    <MenuItem key={client._id} value={client._id}>
                      {capitalizeAllFirstLetters(client.name)}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Project"
                  select
                  value={formInputs.project}
                  onChange={handleChange}
                  name="project"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {project.map((project) => (
                    <MenuItem key={project._id} value={project._id}>
                      {capitalizeAllFirstLetters(project.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Env"
                  value={formInputs.env}
                  onChange={handleChange}
                  name="env"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
              {data.map((entry, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <Typography variant="h6">Entry {index + 1}</Typography>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <IconButton onClick={() => handleDeleteField(index)}>
                      <ClearIcon />
                    </IconButton>

                    <TextField
                      fullWidth
                      label="Access Type"
                      value={entry.outerKey}
                      onChange={(e) =>
                        handleInputChange(index, "outerKey", e.target.value)
                      }
                      style={{ marginBottom: "10px" }}
                      required
                    />

                    {/* <Button
                      size="medium"
                      variant="contained"
                      sx={{ width: "20px", height: "52px" }}
                      onClick={handleAddField}
                    >
                      +
                    </Button> */}
                  </Stack>
                  {entry.innerKeys.map((innerKey, innerIndex) => (
                    <div key={innerIndex} className="  px-5">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                      >
                        <IconButton
                          onClick={() =>
                            handleInnerDeleteField(index, innerIndex)
                          }
                        >
                          <ClearIcon />
                        </IconButton>

                        <TextField
                          fullWidth
                          label={`Name ${innerIndex + 1}`}
                          value={innerKey.innerKey}
                          onChange={(e) =>
                            handleInnerInputChangeName(
                              index,
                              innerIndex,
                              "innerKey",
                              e.target.value
                            )
                          }
                          required
                          style={{ marginRight: "10px" }}
                        />

                        <InputTextOrFile
                          index={index}
                          innerIndex={innerIndex}
                          value="value"
                          innerKey={innerKey}
                          handleInnerInputChange={handleInnerInputChange}
                          setData={setData}
                          path={innerKey.value.value}
                        />
                        {/* <TextField
        fullWidth
        label={`Value ${innerIndex + 1}`}
        value={innerKey.value.value}
        onChange={(e) =>
          handleInnerInputChange(index, innerIndex, "value", e.target.value)
        }
        required
        style={{ marginRight: "10px" }}
      /> */}
                      </Stack>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        // spacing={0}
                        justifyContent="end" // Add this line to center the stack horizontally
                        // sx={{ mb: 2 }}
                      >
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`row-radio-buttons-group-${index}-${innerIndex}`}
                            value={innerKey.value.type} // Set the value of RadioGroup based on type
                            onChange={(e) =>
                              handleInnerInputChangeFile(
                                index,
                                innerIndex,
                                "value",
                                e.target.value
                              )
                            }
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="Text"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="File"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Stack>

                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        // spacing={0}
                        justifyContent="end" // Add this line to center the stack horizontally
                        // sx={{ mb: 2 }}
                      >
                        {entry.innerKeys.length === innerIndex + 1 ? (
                          // <IconButton
                          //   onClick={() => handleInnerAddField(index)}
                          //   style={{ marginRight: "10px" }}
                          // >
                          //   +
                          // </IconButton>

                          <Button
                            size="medium"
                            variant="contained"
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginTop: "8px",
                            }}
                            onClick={() => handleInnerAddField(index)}
                          >
                            +{/* Name {innerIndex + 2} */}
                          </Button>
                        ) : (
                          <IconButton
                            style={{
                              marginRight: "10px",
                              opacity: 0,
                              cursor: "default",
                            }}
                          >
                            +
                          </IconButton>
                        )}
                      </Stack>
                    </div>
                  ))}

                  {entry.innerKeys.length === 0 ? (
                    <IconButton
                      onClick={() => handleInnerAddField(index)}
                      style={{ marginRight: "10px" }}
                    >
                      +
                    </IconButton>
                  ) : null}
                </div>
              ))}

              {/* {data.length === 0 ? (
                <Button
                  size="medium"
                  variant="contained"
                  sx={{ width: "20px", height: "52px" }}
                  onClick={handleAddField}
                >
                  +
                </Button>
              ) : null} */}

              <Button
                size="medium"
                variant="contained"
                sx={{
                  width: "130px",
                  //  height: "52px",
                }}
                onClick={handleAddField}
              >
                + New Entry {data.length + 1}
              </Button>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add Access
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AddAccess;
