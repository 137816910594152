import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Toolbar,
  InputAdornment,
  Zoom,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import ClientListHead from "./components/ClientListHead";
import ClientListToolbar from "./components/ClientListToolbar";
import ClientMoreMenu from "./components/ClientMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { baseUri, s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";

import { useAppContext } from "src/hooks";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { capitalizeFirstLetter, truncateString } from "src/utils/formatText";
import { _list_client } from "src/DAL/clients";
import { _list_admin } from "src/DAL/admins";
import { _list_documentation } from "src/DAL/documentation";
import moment from "moment/moment";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },

  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListDocumentation() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  
  const { enqueueSnackbar } = useSnackbar();



  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  const { _get_user_profile } = useAppContext();
  const profile = _get_user_profile();

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }
  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
   
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
  
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/add-documentation");
  };


  const fetchDocumentation = async () => {
    setIsLoading(true);
    const response = await _list_documentation({search:""});
    if (response.code === 200) {
      console.log(response,"hdsgfjdsfjkhds")
      const documentList = response.documents.map((student, index) => ({
        ...student,
        id: student._id,
        number: index + 1,
      }));
      setStudents(documentList);
      setIsLoading(false); // Set isLoading to false after 2 seconds
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const SearchStudents = async (searchText) => {
    let response = [];
    // setIsLoading(true);
    let search_text = searchText;

    if (!search_text || search_text === "" || search_text === null) {
      search_text = "";
      response = await _list_documentation();
    } else {
      response = await _list_documentation({
        search: search_text,
      });
    }

    // const response = await listStudentSearch(searchText);


    if (response.code === 200) {
      const students = response.documents.map((student, index) => ({
        ...student,
        id: student._id,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchDocumentation();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  function getFormatData(text) {
    if (text.length <= 35) return text;
    return `${text.substring(0, 50)}...`;
  }

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  useEffect(() => {
    console.log("students", students);
  }, [students]);

  useEffect(() => {
    fetchDocumentation();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Admins">
      <Container maxWidth="xl">
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
            mt={2}
          >
            <Typography variant="h4">Documentation List</Typography>

            <Button
              onClick={handleNav}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Documentation
            </Button>
          </Stack>

          <Card
            sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
          >
            {/* <ClientListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

            <Scrollbar>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
                sx={{ padding: "10px" }}
              >
                <TextField
                  label="Search"
                  value={searchText}
                  onChange={handleInputChange}
                />
                {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                <CustomResetButton handleReset={handleReset} />

                {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
              </Stack>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ClientListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={students.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  <TableBody>
                    {filteredStudents
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const {
                          id,
                          name,
                          status,
                          number,
                          title,
                          description,
                          
                          createdAt,
                        } = row;
                        console.log(row,"jgfdjkshfkjd")
                        const isItemSelected =
                          selected.indexOf(title) !== -1;
                        // let number = index + 1;
                        // let { course, others } = row;
                        // let titleToShow = others.length > 0 ? others[0].course_title : course[0].title;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, name)
                              }
                            /> */}
                            </TableCell>

                            <TableCell align="left" style={myStyle}>
                              {index + 1}
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              <div
                                className="on_click_text1"
                                // style={{
                                //   cursor: "pointer",
                                // }}
                                // onClick={() => handleOpenModal(row)}
                              >
                                {capitalizeFirstLetter(title)}
                              </div>
                            </TableCell>

                            <TableCell align="left" style={myStyle}>
                              <div
                                className="on_click_text1"
                                // style={{
                                //   cursor: "pointer",
                                // }}
                                // onClick={() => handleOpenModal(row)}
                              >
                                {getFormatData(description)}
                              </div>
                            </TableCell>

                           
                            <TableCell align="left">
                              {moment(createdAt).format("YYYY-MM-DD")}
                            </TableCell>

                            <TableCell align="right">
                              {/* {profile.user_id._id === row.user_id ? ( */}
                              <ClientMoreMenu
                                row={row}
                                students={students}
                                onStudentsUpdate={handleStudentsUpdate}
                              />
                              {/* ) : null} */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[
                50, 100, 150,
                // { value: students.length, label: "All" },
              ]}
              component="div"
              count={students.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </>
      </Container>

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Admin Detail"
        componentToPassDown={
          <div className="mt-5">
            {/* <BrandDetail selectedRowStudent={selectedRowStudent} /> */}
          </div>
        }
      />
    </Page>
  );
}
