import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { useSnackbar } from "notistack";
import {
  _code_verification,
  _email_verification,
  _verify_google_auth,
  login,
} from "src/DAL/auth";
import { useAppContext } from "src/hooks";
import CustomModal from "src/components/GeneralComponents/CustomModal";

// ----------------------------------------------------------------------

export default function EmailAuthenticator({
  formInputs,
  setFormInputs,
  selectedRowStudent,
}) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isEmailCode, setIsEmailCode] = useState(false);
  const [showGetCodeText, setShowGetCodeText] = useState(true);
  const [counter, setCounter] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { _set_user_profile } = useAppContext();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const getEmailCode = async () => {
    setIsLoadingButton(true);
    setShowGetCodeText(false);
    const response = await _email_verification({
      email: formInputs.email,
    });
    console.log("_email_verification _email_verification", response);

    if (response.code === 200) {
      setIsEmailCode(true);
      setIsLoadingButton(false);
      setCounter(60); // Start the counter from 60 seconds
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 0) {
            setShowGetCodeText(true);
            clearInterval(intervalId);
            // setIsEmailCode(false); // Enable "Get Code" button after the countdown
          }
          return prevCounter === 0 ? 0 : prevCounter - 1;
        });
      }, 1000);
    } else {
      setShowGetCodeText(true);
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoadingButton(false);
    }
  };

  const handleSubmitCode = async (e) => {
    e.preventDefault();
    const obj = {
      email: formInputs.email.trim(),
      verification_code: formInputs.code,
    };

    setIsLoadingButton(true);

    const result = await _code_verification(obj);
    console.log(
      "_code_verification _code_verification ___login __test",
      result
    );
    if (result.code === 200) {
      localStorage.setItem("token", result.token);

      let user = {
        ...result.user,
        first_name: result.user.first_name,
        last_name: result.user.last_name,
        email: result.user?.user_id.email,
        image: result.user.profile_image,
      };

      localStorage.setItem("user", JSON.stringify(user));
      _set_user_profile(user);
      navigate("/dashboard");
      setIsLoadingButton(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    // if (counter < 1) {
    //   setShowGetCodeText(true);
    // }
  }, [counter]);

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        mt={5}
        mb={5}
        pt={5}
        pb={5}
      >
        <Typography variant="h5">Get Your Code</Typography>
        <Typography variant="body2" align="center">
          Please enter the 6-digit code sent to your email address.
        </Typography>
      </Stack>

      <form autoComplete="off" onSubmit={handleSubmitCode}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              value={formInputs.code}
              onChange={handleChange}
              name="code"
              required
              fullWidth
              type="code"
              label="Email Authenticator Code"
              disabled={!isEmailCode}
            />
            <LoadingButton
              sx={{ height: "50px", marginTop: "0px" }}
              size="small"
              type="submit"
              variant="contained"
              loading={isLoadingButton}
              disabled={!isEmailCode}
            >
              <Iconify icon={"zondicons:arrow-right"} width={22} height={22} />
            </LoadingButton>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <Typography
            variant="subtitle2"
            underline="hover"
            component={Link} // Use Link component from MUI for better styling and accessibility
            onClick={getEmailCode} // Call getEmailCode when clicked
            style={{ cursor: "pointer" }} // Optional: Change cursor to pointer to indicate it's clickable
          >
            Get Code
          </Typography> */}
          {counter > 0 ? (
            <Typography variant="subtitle2" underline="hover">
              Resend Code in {counter} seconds
            </Typography>
          ) : (

            showGetCodeText?( 
            <Typography
              variant="subtitle2"
              underline="hover"
              component={Link} // Use Link component from MUI for better styling and accessibility
              onClick={getEmailCode} // Call getEmailCode when clicked
              style={{ cursor: "pointer" }} // Optional: Change cursor to pointer to indicate it's clickable
            >
              Get Code
            </Typography>
            ):null
          )}
        </Stack>
      </form>
    </>
  );
}
