import React, { useState, useEffect } from "react";

const Context = React.createContext();
const AppContext = ({ children }) => {
  const [profile, setProfile] = useState({
    first_name: "Dynamic",
    last_name: "Logix",
    email: "example@gmail.com",
    image: "",
    type: 0,
    privilege: {},
  });

  let _get_user_info = JSON.parse(localStorage.getItem("user"));

  const [privilegeAccess, setPrivilegeAccess] = useState(
    _get_user_info == null ? {} : _get_user_info.privilege
  );

  const [contactCount, setContactCount] = useState(0);
  //------------------------------------------------------
  const _get_contact_count = () => {
    return contactCount;
  };
  const _set_contact_count = (value) => {
    console.log("value   ____test ___getContactCount",value);
    setContactCount(value);
  };
  const _get_user_profile = () => {
    return profile;
  };
  const _set_user_profile = (value) => {
    setProfile(value);
  };

  const _get_user_privilege_access = () => {
    return privilegeAccess;
  };

  const _set_user_privilege_access = (value) => {
    setPrivilegeAccess(value);
  };

  //------------------------------------------------------
  useEffect(() => {
    const storedObjectString = localStorage.getItem("user");
    const storedObject = JSON.parse(storedObjectString);
    _set_user_profile(storedObject);
    return () => {
      //cleanup
    };
  }, []);
  //------------------------------------------------------
  const bundle = {
    _get_contact_count,
    _set_contact_count,
    _get_user_profile,
    _set_user_profile,
    _get_user_privilege_access,
    _set_user_privilege_access,
  };

  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => React.useContext(Context);
