import RefreshIcon from "@mui/icons-material/Refresh"; // You can replace RefreshIcon with the icon you prefer
import Iconify from "../Iconify";
import {
    Stack,
    TextField,
    MenuItem,
    Input,
    IconButton,
    Typography,
    Grid,
    Box,
    Autocomplete,
    InputAdornment,
  } from "@mui/material";
import { useState } from "react";
// ----------------------------------------------------------------------

export default function CustomPasswordField({ passwordVariable, setPasswordVariable, passwordLabel,Required,show }) {
    const [showPassword, setShowPassword] = useState(show?show:false); 

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPasswordVariable(value);
        // setFormInputs((prevInputs) => ({
        //   ...prevInputs,
        //   [name]: value,
        // }));
      };

      const handleShowPassword = () => {
        setShowPassword((show) => !show);
      };

  return (
    <>
      <TextField
                  fullWidth
                  label={passwordLabel}
                  value={passwordVariable}
                  onChange={handleChange}
                  name="passwordVariable"
                  required={Required}  // Conditionally set the required attribute
                  autoComplete="new-password"
                  //   autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
    </>
  );
}
