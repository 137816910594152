import { invokeApi } from "src/utils";
export const delImage = async (data) => {
  const requestObj = {
    // path: `api/app_api/delete_image`,
    path: `api/app_api/delete_file_from_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
}; 


export const _delete_file_from_google_drive = async (id) => {
  const requestObj = {
    path: `api/app_api/delete_file_from_google_drive/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _download_file_from_google_drive = async (id) => {
  const requestObj = {
    path: `api/app_api/download_file_from_google_drive/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
          'Access-Control-Allow-Origin': "*"
    },
  };
  return invokeApi(requestObj);
};
