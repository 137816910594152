import { invokeApi } from 'src/utils';

export const _dashboard_init = async () => {
    const requestObj = {
      path: `api/init/init`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

 