import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'notistack';
import CustomModal from 'src/components/GeneralComponents/CustomModal';
import EmailAuthenticator from 'src/pages/login/components/EmailAuthenticator';
import EmailGetCode from './EmailGetCode';
import ResetPassword from './ResetPassword';
import { _email_verification, _reset_password } from 'src/DAL/auth';

// ----------------------------------------------------------------------

export default function ForgotForm() {
  const navigate = useNavigate();
  // const [formInputs, setFormInputs] = useState({ email: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const myElementRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const [screen, setScreen] = useState(1);
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  const [formInputs, setFormInputs] = useState({
    email: "",
    password: "",
    code: "",
    authenticator: "email", // Default to "email" option
  });


  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const data = new FormData();
    // data.append('email', formInputs.email.trim()); 


    console.log("formInputs", formInputs);

    let postData = {
        email: formInputs.email.trim(),
    };
 

    console.log("postData", postData);

    setIsLoading(true);
    const result = await _email_verification(postData);
    console.log("result _email_verification", result);

    if (result.code === 200) {
      setIsLoading(false);
      // enqueueSnackbar("Reset Password Successfully!", { variant: "success" });
      handleOpenModal( "Forgot Password");
    //   navigate(-1);
      // navigate("/login");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }


   
  };

  return (
    <> 
    <form autoComplete='off' onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          value={formInputs.email}
          onChange={handleChange}
          name='email'
          required
          fullWidth
          autoComplete='username'
          type='email'
          label='Email address'
          sx={{ mb: 3 }}
        />
      </Stack>
      <Stack justifyContent='center' alignItems='center'>
        <div style={{ width: 'fit-content' }}>
          <LoadingButton size='large' type='submit' variant='contained' loading={isLoading}>
            Continue
          </LoadingButton>
        </div>
      </Stack>
    </form>



<CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Forgot Password"
        componentToPassDown={
          <div className="mt-5">
            <div className="container mt-5 px-5">
              
              {screen===1?( 
                <EmailGetCode
                  formInputs={formInputs}
                  setFormInputs={setFormInputs}
                  selectedRowStudent={selectedRowStudent}
                  screen={screen}
                  setScreen={setScreen}
                />
                ):(
                  <div className='pt-1'> 
                  <ResetPassword
                  formInputs={formInputs}
                  setFormInputs={setFormInputs}
                />
                </div>

                  
                  
                  )}
             
            </div>
          </div>
        }
      />
     </>
  );
}
