import { invokeApi } from "src/utils";
export const addImage = async (data) => {
  const requestObj = {
    // path: `api/app_api/upload_file_without_resize`,
    // path: `api/app_api/upload_file`,
    path: `api/app_api/upload_file_on_google_drive`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addImageWithSize = async (data) => {
  const requestObj = {
    // path: `api/app_api/upload_image_on_s3`,
    // path: `api/app_api/upload_file`,
    path: `api/app_api/upload_file_on_google_drive`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
