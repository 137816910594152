import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_CRYPTO_KEY;

export function encryptValue(value) {
  return CryptoJS.AES.encrypt(value, secretKey).toString();
}

export function decryptValue(encryptedValue) {
  const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
