import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  MenuItem,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  truncateString,
} from "src/utils/formatText";
import { CircularLoader, Iconify, Page } from "src/components";
import { _active_projects, _add_project } from "src/DAL/projects";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  _add_access,
  _detail_access,
  _edit_access,
  _get_file_details,
} from "src/DAL/access";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { getFileIcon } from "src/utils/formatOther";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { decryptValue } from "src/utils/crypto";

const DetailAccess = () => {
  const [data, setData] = useState([
    {
      outerKey: "Backend",
      innerKeys: [
        {
          innerKey: "Domain Name",
          value: {
            type: "1",
            value: "",
          },
        },

        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File Path",
          value: {
            type: "2",
            value: "",
          },
        },
        {
          innerKey: "GitHub Repo",
          value: {
            type: "1",
            value: "",
          },
        },

        {
          innerKey: "GitHub Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Developer Name",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },

    {
      outerKey: "Enviroment",
      innerKeys: [
        {
          innerKey: "Env",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },

    {
      outerKey: "S3 Bucket",
      innerKeys: [
        {
          innerKey: "S3 Access Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "S3 Secret Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "S3 Bucket Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "S3 Region",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "SES",
      innerKeys: [
        {
          innerKey: "SES Access Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SES Secret Key",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SES Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SES Region",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "DataBase",
      innerKeys: [
        {
          innerKey: "Database Url",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },

    {
      outerKey: "Client",
      innerKeys: [
        {
          innerKey: "Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "User Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "IP",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Password",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File",
          value: {
            type: "1",
            value: "",
          },
        },

        {
          innerKey: "Repository",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Repository Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Env",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Developer",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "Admin",
      innerKeys: [
        {
          innerKey: "Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "User Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "IP",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Password",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File",
          value: {
            type: "1",
            value: "",
          },
        },

        {
          innerKey: "Repository",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Repository Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Env",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Developer",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
    {
      outerKey: "Next Website",
      innerKeys: [
        {
          innerKey: "Domain",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "User Name",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "IP",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Password",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server SSH String",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Server Pem-File",
          value: {
            type: "1",
            value: "",
          },
        },

        {
          innerKey: "Repository",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Repository Branch",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Env",
          value: {
            type: "1",
            value: "",
          },
        },
        {
          innerKey: "Developer",
          value: {
            type: "1",
            value: "",
          },
        },
      ],
    },
  ]);

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [access, setAccess] = useState({
    _id: "",
    client: {
      _id: "",
      name: "",
    },
    project: {
      _id: "",
      name: "",
    },
    env: "",
    access: [],
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleNav = () => {
    navigate(-1);
  };

  const handleDirectDownload = (downloadLink) => {
    const anchor = document.createElement("a");
    anchor.href = downloadLink;
    anchor.setAttribute("target", "_blank"); // Open in new tab
    anchor.style.display = "none"; // Hide the anchor element
    document.body.appendChild(anchor);
    anchor.click(); // Programmatically trigger the click event
    document.body.removeChild(anchor); // Remove the anchor element from the DOM after download
  };

  const handleTextClick = async (textToCopy, type) => {
    if (type === 1) {
      navigator.clipboard.writeText(textToCopy);
      enqueueSnackbar("Copied to clipboard", {
        variant: "success",
      });
    } else {
      const response = await _get_file_details(textToCopy);

      console.log("response  ___response", response);
      if (response.code === 200) {
        // Copy text to clipboard

        if (type === 2) {
          navigator.clipboard.writeText(response.file.preview_path);
          enqueueSnackbar("Copied to clipboard", {
            variant: "success",
          });
        } else {
          handleDirectDownload(response.file.download_link);
        }
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        // setIsLoading(false);
      }
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_access(id);
    console.log("_detail_access _detail_access", response);

    if (response.code === 200) {
      // const [access, setAccess] = useState([]);

      setAccess(response.access);
      // Reverse the transformation to get back the original format
      let originalData = response.access.access.map((entry) => {
        let outerKey = Object.keys(entry)[0]; // Extract the outer key
        let innerKeys = Object.entries(entry[outerKey]).map(
          ([innerKey, value]) => ({
            innerKey,
            value: {
              ...value,
              value:
                value.type === "1" ? decryptValue(value.value) : value.value,
            },
          })
        ); // Extract inner keys
        return { outerKey, innerKeys };
      });

      setData(originalData);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Access View">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4
            className="title_heading_in_all_pages_top_left"
            style={{ fontWeight: 600 }}
          >
            {" "}
            Access{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack spacing={2}>
            <Card>
              <CardContent>
                <Stack spacing={1}>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between"
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Client Name &nbsp;</span>
                    <span className="px-4">{access.client.name}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between"
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Project Name</span>
                    <span className="px-4">{access.project.name}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between"
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Environment&nbsp;</span>
                    <span className="px-4">{access.env}</span>
                  </div>
                </Stack>
              </CardContent>
            </Card>

            {data.map((entry, index) => (
              <Card key={index}>
                <CardContent>
                  <Typography
                    sx={{ fontWeight: 600 }}
                    variant="h5"
                    component="div"
                    className="mb-3"
                  >
                    {entry.outerKey}
                  </Typography>
                  {/* <Typography variant="body1" color="text.secondary">
                    {entry.outerKey}
                  </Typography> */}
                  <Stack spacing={1}>
                    {entry.innerKeys.map((innerKey, innerIndex) => (
                      <div key={innerIndex}>
                        <div
                          style={{
                            display: "flex",
                            // justifyContent: "space-between"
                          }}
                        >
                          <span style={{ fontWeight: 600 }}>
                            {innerKey.innerKey}
                          </span>
                          <span className="px-4" style={{ marginTop: "-3px" }}>
                            {truncateString(innerKey.value.value, 20)}

                            {innerKey.value.type === "1" ? (
                              <IconButton
                                size="small"
                                sx={{ marginTop: "-4px" }}
                              >
                                <FileCopyIcon
                                  onClick={() =>
                                    handleTextClick(innerKey.value.value, 1)
                                  }
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                size="small"
                                sx={{ marginTop: "-4px" }}
                              >
                                <FileCopyIcon
                                  onClick={() =>
                                    handleTextClick(innerKey.value.value, 2)
                                  }
                                />
                              </IconButton>
                            )}

                            {innerKey.value.type === "2" ? (
                              <IconButton
                                size="small"
                                sx={{ marginTop: "-4px" }}
                                onClick={() =>
                                  handleTextClick(innerKey.value.value, 3)
                                }
                              >
                                <Iconify
                                  icon={"line-md:download-loop"}
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            ) : null}
                          </span>
                        </div>
                      </div>
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Grid>
      </div>
    </Page>
  );
};

export default DetailAccess;
