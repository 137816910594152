import { invokeApi } from "src/utils";


export const _detail_qr_code = async () => {
    const requestObj = {
      path: `api/app_api/detail_qr_code`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

export const _generate_qr_code = async () => {
    const requestObj = {
      path: `api/app_api/generate_qr_code`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

    export const _verify_google_auth = async (data) => {

    const requestObj = {
      path: `api/app_api/verify_google_auth`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
